import React, { useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";

export default function ApiPage() {
  const [checkReservationId, {}] = useLazyQuery(gql`
    query getReservationId($id: ID!, $key: String!) {
      reservations(
        publicationState: PREVIEW
        filters: { id: { eq: $id }, key: { eq: $key } }
      ) {
        data {
          id
          attributes {
            key
          }
        }
      }
    }
  `);

  const [updateReservation, {}] = useMutation(gql`
    mutation updateReservation($id: ID!, $state: ENUM_RESERVATION_STATUS!) {
      updateReservation(id: $id, data: { status: $state }) {
        data {
          id
        }
      }
    }
  `);

  const changeReservation = async () => {
    const params = new URLSearchParams(location.search);

    const id = params.get("id");
    const key = params.get("key");
    let state = null;
    switch (params.get("state")) {
      case "0":
        state = "rejected";
      case "1":
        state = "accepted";
    }
    if (state && key && id) {
      const { data, error } = await checkReservationId({
        variables: {
          id: id,
          key: key,
        },
      });
      if (!error) {
        const isValid = data.reservations.data.length > 0;
        if (isValid) {
          const { data, errors } = await updateReservation({
            variables: {
              id: id,
              state: state,
            },
          });
          const updatedId = data.updateReservation.data.id;
          if (!errors && updatedId) {
            alert("REZERWACJA POTWIERDZONA, MOŻNA ZAMKNĄĆ TO OKNO");
          }
        }
      }
    }
    return 1;
  };

  useEffect(() => {
    changeReservation();
  }, []);

  return <div>.</div>;
}
